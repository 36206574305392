import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** Map of Feature switch and its value for the org. */
  FeatureSwitchOverride: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: { input: any; output: any };
  /** Custom scalar for string and array of strings */
  StringOrArray: { input: any; output: any };
};

/** Details for an API token object */
export type ApiTokenDetails = AuthResourceDetails & {
  __typename?: 'APITokenDetails';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  serviceIdentityRef: Scalars['ObjectID']['output'];
  type: AuthResourceType;
};

/** AWS integration connection details */
export type AwsIntegration = Integration & {
  __typename?: 'AWSIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Details for an access key object */
export type AccessKeyDetails = AuthResourceDetails & {
  __typename?: 'AccessKeyDetails';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  serviceIdentityRef: Scalars['ObjectID']['output'];
  status: AccessKeyStatus;
  type: AuthResourceType;
};

/** Status of the access key auth resource */
export enum AccessKeyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Active Directory integration details */
export type ActiveDirectoryIntegration = Integration & {
  __typename?: 'ActiveDirectoryIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** A count of activities by a service/identity filter */
export type ActivitiesCount = {
  __typename?: 'ActivitiesCount';
  count: Scalars['Int']['output'];
};

/** Activity logs for an identity */
export type Activity = {
  __typename?: 'Activity';
  _id: Scalars['ObjectID']['output'];
  actionType: Scalars['String']['output'];
  actorName?: Maybe<Scalars['String']['output']>;
  actorType: ActorType;
  authResources?: Maybe<Array<AuthResource>>;
  detail?: Maybe<ActivityDetail>;
  identities?: Maybe<Array<ServiceIdentity>>;
  org: Org;
  service: Service;
  timestamp: Scalars['DateTime']['output'];
};

export type ActivityDetail = {
  activityId: Scalars['String']['output'];
  integrationId: IntegrationId;
};

/** Natoma actor type */
export enum ActorType {
  AccessToken = 'ACCESS_TOKEN',
  Anonymous = 'ANONYMOUS',
  ApiKey = 'API_KEY',
  External = 'EXTERNAL',
  System = 'SYSTEM',
  Uncategorized = 'UNCATEGORIZED',
  User = 'USER'
}

/** A customer-facing alert */
export type Alert = {
  __typename?: 'Alert';
  _id: Scalars['ObjectID']['output'];
  accepted: Scalars['Boolean']['output'];
  /** The alert message */
  message: Scalars['String']['output'];
  org: Org;
  /** The owner of the alert */
  owner: User;
  /** The alert recommendation */
  recommendation: Scalars['String']['output'];
  /** The severity of the alert */
  severity: AlertSeverity;
  status: AlertStatus;
  target: AlertTarget;
  /** The type of alert */
  type: AlertName;
};

/** The name of the alert */
export enum AlertName {
  AuthenticatorNeedsRotation = 'AUTHENTICATOR_NEEDS_ROTATION',
  InactiveAuthenticator = 'INACTIVE_AUTHENTICATOR'
}

/** The severity of the alert */
export enum AlertSeverity {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

/** The status of an alert */
export enum AlertStatus {
  Active = 'ACTIVE',
  Dismissed = 'DISMISSED'
}

/** Target entity of the alert */
export type AlertTarget = {
  __typename?: 'AlertTarget';
  ref?: Maybe<Scalars['ObjectID']['output']>;
  type?: Maybe<AlertTargetType>;
};

/** The type of an alert target */
export enum AlertTargetType {
  Authenticator = 'AUTHENTICATOR',
  Identity = 'IDENTITY',
  ServiceIdentity = 'SERVICE_IDENTITY'
}

/** Details for app password */
export type AppPasswordDetails = AuthResourceDetails & {
  __typename?: 'AppPasswordDetails';
  type: AuthResourceType;
};

/** An auth resource provided or managed by a third party service, and owned or assigned to one or more service identities */
export type AuthResource = {
  __typename?: 'AuthResource';
  _id: Scalars['ObjectID']['output'];
  createdOn?: Maybe<Scalars['DateTime']['output']>;
  details?: Maybe<AuthResourceDetails>;
  externalId: Scalars['String']['output'];
  integrationId: IntegrationId;
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org: Org;
  service: Service;
  /** All service identities associated with this resource */
  serviceIdentities: Array<ServiceIdentity>;
  status: AuthResourceStatus;
};

/** An auth resource provided or managed by a third party service, and owned or assigned to one or more service identities */
export type AuthResourceServiceIdentitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AuthResourceDetails = {
  type: AuthResourceType;
};

/** The status of a resource associated with a service */
export enum AuthResourceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Tombstoned = 'TOMBSTONED'
}

/** The kind of resource represented */
export enum AuthResourceType {
  AccessKey = 'ACCESS_KEY',
  ApiToken = 'API_TOKEN',
  AppPassword = 'APP_PASSWORD',
  Group = 'GROUP',
  OauthToken = 'OAUTH_TOKEN',
  PermissionSet = 'PERMISSION_SET',
  Role = 'ROLE',
  RoleAssignment = 'ROLE_ASSIGNMENT'
}

/** Input body for updating multiple Identity objects. */
export type BulkUpdatesIdentitiesInput = {
  _ids: Array<InputMaybe<Scalars['ObjectID']['input']>>;
  patch: IdentityUpdatePatch;
};

/** A classification for an identity */
export type Classification = {
  __typename?: 'Classification';
  accountType?: Maybe<IdentityAccountType>;
  actor?: Maybe<User>;
  decidedAt: Scalars['DateTime']['output'];
  scope?: Maybe<IdentityScope>;
};

/** Rule used to classify identities as human or non-human */
export type ClassificationRule = {
  __typename?: 'ClassificationRule';
  _id: Scalars['ObjectID']['output'];
  category: MatchType;
  description?: Maybe<Scalars['String']['output']>;
  property: Scalars['String']['output'];
  rule: Scalars['String']['output'];
  scope: IdentityScope;
  targetType: ClassificationRuleTargetType;
  type: ClassificationRuleType;
};

/** Fields for the create body */
export type ClassificationRuleCreateBody = {
  /** The kind of identity this classification rule matches */
  category: MatchType;
  /** Optional user-provided description for this rule, for context or summary */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The property of a normalized identity that this classification rule matches against */
  property: Scalars['String']['input'];
  /** The value for this rule, interpreted based on the type */
  rule: Scalars['String']['input'];
  /** The scope of the identity this classification rule matches */
  scope: IdentityScope;
  /** The target type of this classification rule */
  targetType: ClassificationRuleTargetType;
  /** The kind of match this is, basic classification rules are either exact string matches, substring matches, or regex matches */
  type: ClassificationRuleType;
};

/** The types of matched identities */
export enum ClassificationRuleTargetType {
  Group = 'GROUP',
  Identity = 'IDENTITY',
  Resource = 'RESOURCE'
}

/** Rule types for classification rules, used for categorizing identities */
export enum ClassificationRuleType {
  ExactMatch = 'EXACT_MATCH',
  Regex = 'REGEX',
  SubstringMatch = 'SUBSTRING_MATCH',
  TrieSearch = 'TRIE_SEARCH'
}

/** Rule used to classify identities as human or non-human */
export type ClassificationRuleset = {
  __typename?: 'ClassificationRuleset';
  _id: Scalars['ObjectID']['output'];
  createdAt: Scalars['DateTime']['output'];
  org: Org;
  rules: Array<Maybe<ClassificationRule>>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Fields to create a new ruleset body */
export type ClassificationRulesetCreateBody = {
  /** A list of classification rules to apply ordered by priority */
  rules?: InputMaybe<Array<InputMaybe<ClassificationRuleCreateBody>>>;
};

/** Required information to create an ingest job */
export type CreateIngestJobInput = {
  context?: InputMaybe<Scalars['ObjectID']['input']>;
  contextType?: InputMaybe<JobContextType>;
  dependsOn?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  type: JobType;
};

export type CreateIntegrationInput = {
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['input'];
  authorizedScopes: Array<Scalars['String']['input']>;
  integrationId: IntegrationId;
};

/** Required information to create a job chain */
export type CreateJobChainInput = {
  context: Scalars['ObjectID']['input'];
  contextType: JobContextType;
  jobChainType: JobChainType;
};

/** A simple type to confirm the deletion of a particular object */
export type DeleteResult = {
  __typename?: 'DeleteResult';
  _id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** User data from descope (used for authenticating users). */
export type DescopeData = {
  __typename?: 'DescopeData';
  /** The user ID from Descope. */
  userId?: Maybe<Scalars['String']['output']>;
};

/** Entra integration details */
export type EntraIntegration = Integration & {
  __typename?: 'EntraIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** A general error object */
export type Error = {
  __typename?: 'Error';
  message: Scalars['String']['output'];
};

/** Details for a generic activity */
export type GenericActivityDetail = ActivityDetail & {
  __typename?: 'GenericActivityDetail';
  activityId: Scalars['String']['output'];
  integrationId: IntegrationId;
};

/** Different sorts for the activities query */
export enum GetActivitiesSort {
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC'
}

/** Different fields to sort by for the authResources query */
export enum GetAuthResourcesSort {
  LastActivityAsc = 'LAST_ACTIVITY_ASC',
  LastActivityDesc = 'LAST_ACTIVITY_DESC'
}

/** Different sorts for the identities query */
export enum GetIdentitiesSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Different sorts for the orgs query */
export enum GetOrgsSort {
  DisplaynameAsc = 'DISPLAYNAME_ASC',
  DisplaynameDesc = 'DISPLAYNAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC'
}

/** Different fields to sort by for the service identities query */
export enum GetServiceIdentitiesSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Different sorts for the users query */
export enum GetUsersSort {
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC'
}

/** Github activity detail */
export type GithubActivityDetail = ActivityDetail & {
  __typename?: 'GithubActivityDetail';
  activityId: Scalars['String']['output'];
  actorCountryCode?: Maybe<Scalars['String']['output']>;
  githubActionTypes?: Maybe<Scalars['String']['output']>;
  githubTargetUser?: Maybe<Scalars['String']['output']>;
  githubTargetUserId?: Maybe<Scalars['Int']['output']>;
  githubUserId?: Maybe<Scalars['Int']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  org?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['Int']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  repo?: Maybe<Scalars['String']['output']>;
  repoPublic?: Maybe<Scalars['Boolean']['output']>;
  team?: Maybe<Scalars['String']['output']>;
};

/** GitHub integration connection details */
export type GithubIntegration = Integration & {
  __typename?: 'GithubIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  organization?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** Google workspace activity detail */
export type GoogleWorkspaceActivityDetail = ActivityDetail & {
  __typename?: 'GoogleWorkspaceActivityDetail';
  activityId: Scalars['String']['output'];
  actorIpAddress?: Maybe<Scalars['String']['output']>;
  applicationName?: Maybe<Scalars['String']['output']>;
  googleUserId?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
};

/** Google workspace connection details */
export type GoogleWorkspaceIntegration = Integration & {
  __typename?: 'GoogleWorkspaceIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  scope?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  tokenType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Details for a group object */
export type GroupDetails = AuthResourceDetails & {
  __typename?: 'GroupDetails';
  authResources: Array<Maybe<Scalars['ObjectID']['output']>>;
  members: Array<Maybe<Scalars['ObjectID']['output']>>;
  type: AuthResourceType;
};

/** A non-human identity within Natoma, derived from individual service identities */
export type Identity = {
  __typename?: 'Identity';
  _id: Scalars['ObjectID']['output'];
  classification?: Maybe<Classification>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  org: Org;
  owner?: Maybe<User>;
  /** All service identities associated with this identity */
  serviceIdentities: Array<ServiceIdentity>;
  serviceIdentityRef?: Maybe<ServiceIdentity>;
  servicesCount?: Maybe<Scalars['Int']['output']>;
  status: IdentityStatus;
  userRef?: Maybe<User>;
};

/** A non-human identity within Natoma, derived from individual service identities */
export type IdentityServiceIdentitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The type of account this identity represents */
export enum IdentityAccountType {
  Human = 'HUMAN',
  NonHuman = 'NON_HUMAN',
  Uncategorized = 'UNCATEGORIZED',
  Unknown = 'UNKNOWN'
}

/** Input body for creating an Identity object. */
export type IdentityCreateBody = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['ObjectID']['input']>;
  serviceId?: InputMaybe<Scalars['ObjectID']['input']>;
};

/** identity or error object */
export type IdentityOrError = Error | Identity;

/** The principal of a given service identity */
export type IdentityPrincipal = {
  __typename?: 'IdentityPrincipal';
  principal: Scalars['String']['output'];
  type: IdentityPrincipalType;
};

/** The type of principal by which this identity is represented, e.g. email, uid */
export enum IdentityPrincipalType {
  AppId = 'APP_ID',
  Email = 'EMAIL',
  IntegrationName = 'INTEGRATION_NAME',
  PublicKey = 'PUBLIC_KEY',
  Uid = 'UID'
}

/** The scope of a identity */
export enum IdentityScope {
  InScope = 'IN_SCOPE',
  OutOfScope = 'OUT_OF_SCOPE'
}

/** The status of a non-human identity */
export enum IdentityStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE'
}

/** Input body for updating an Identity object. */
export type IdentityUpdateBody = {
  /** ID of the identity to update the type. */
  _id: Scalars['String']['input'];
  patch: IdentityUpdatePatch;
};

/** Patch object for updating an identity */
export type IdentityUpdatePatch = {
  accountType?: InputMaybe<IdentityAccountType>;
  ownerId?: InputMaybe<Scalars['ObjectID']['input']>;
};

/** The processing metadata of an ingest job */
export type IngestJobProcessingMetadata = {
  __typename?: 'IngestJobProcessingMetadata';
  expectedIdentities?: Maybe<Scalars['Int']['output']>;
  processedIdentities?: Maybe<Scalars['Int']['output']>;
};

export type Integration = {
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  status: IntegrationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** The available authentication types for an integration */
export enum IntegrationAuthTypes {
  ApiKey = 'API_KEY',
  AsyncConfiguration = 'ASYNC_CONFIGURATION',
  ClientCredentials = 'CLIENT_CREDENTIALS',
  Install = 'INSTALL',
  Oauth = 'OAUTH',
  ServiceAccount = 'SERVICE_ACCOUNT'
}

/** A sum of configured services for a given integrationId */
export type IntegrationCount = {
  __typename?: 'IntegrationCount';
  count: Scalars['Int']['output'];
  integrationId: IntegrationId;
};

/** Set of available integration IDs */
export enum IntegrationId {
  ActiveDirectory = 'ACTIVE_DIRECTORY',
  Aws = 'AWS',
  Entra = 'ENTRA',
  Github = 'GITHUB',
  GoogleWorkspace = 'GOOGLE_WORKSPACE',
  Okta = 'OKTA',
  Salesforce = 'SALESFORCE',
  Slack = 'SLACK',
  Snowflake = 'SNOWFLAKE'
}

/** The status of an integration connection */
export enum IntegrationStatus {
  Active = 'ACTIVE',
  Disconnected = 'DISCONNECTED'
}

/** Input for inviting a user to the current organization. */
export type InviteUserInput = {
  /** The email address of the user to invite. */
  email: Scalars['String']['input'];
  /** The role of the user to invite. */
  role?: UserRole;
};

/** A record for a identity ingest job */
export type Job = {
  __typename?: 'Job';
  _id: Scalars['ObjectID']['output'];
  adminInitiated?: Maybe<Scalars['Boolean']['output']>;
  areDependenciesCompleted?: Maybe<Scalars['Boolean']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  context?: Maybe<JobContext>;
  dependsOn?: Maybe<Array<Maybe<Scalars['ObjectID']['output']>>>;
  error?: Maybe<Scalars['String']['output']>;
  missingScopes?: Maybe<Array<Scalars['String']['output']>>;
  org: Org;
  processingMetadata?: Maybe<IngestJobProcessingMetadata>;
  queuedAt: Scalars['DateTime']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: JobStatus;
  triggeredBy?: Maybe<User>;
  type: JobType;
};

/** A chain of jobs */
export type JobChain = {
  __typename?: 'JobChain';
  _id: Scalars['ObjectID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  context?: Maybe<JobContext>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  missingScopes?: Maybe<Array<Scalars['String']['output']>>;
  org: Org;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: JobStatus;
  type: JobChainType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** The type of job chain */
export enum JobChainType {
  Daily = 'DAILY',
  Ingest = 'INGEST'
}

/** The context of an job */
export type JobContext = {
  __typename?: 'JobContext';
  ref?: Maybe<Scalars['ObjectID']['output']>;
  service?: Maybe<Service>;
  type?: Maybe<JobContextType>;
};

/** The type of document to which the context ref refers */
export enum JobContextType {
  Service = 'Service',
  ServiceIdentity = 'ServiceIdentity'
}

/** The status of a job */
export enum JobStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Queued = 'QUEUED'
}

/** The type of this job */
export enum JobType {
  Activity = 'ACTIVITY',
  ActivityProcess = 'ACTIVITY_PROCESS',
  AuthResource = 'AUTH_RESOURCE',
  Classification = 'CLASSIFICATION',
  Group = 'GROUP',
  Identity = 'IDENTITY',
  OrgLevel = 'ORG_LEVEL',
  PeriodicAlert = 'PERIODIC_ALERT',
  Service = 'SERVICE',
  TargetResource = 'TARGET_RESOURCE',
  UserDetail = 'USER_DETAIL'
}

/** The types of matched identities */
export enum MatchType {
  Human = 'HUMAN',
  NonHuman = 'NON_HUMAN'
}

export type MeResponse = {
  __typename?: 'MeResponse';
  /** The current logged in org. */
  currentOrg?: Maybe<Org>;
  /** The logged-in current user. */
  currentUser?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createClassificationRuleset: ClassificationRuleset;
  createIdentity: Identity;
  createIngestJob: Job;
  createJobChain: Array<Job>;
  createService: Service;
  createServiceFromIntegration: Service;
  deleteServiceIntegration?: Maybe<DeleteResult>;
  deleteUser: User;
  inviteUser: User;
  register: MeResponse;
  updateIdentities: Array<Identity>;
  updateIdentity: Identity;
  updateOrg: Org;
  updateOrgFeatureSwitch: Org;
  updateService: Service;
};

export type MutationCreateClassificationRulesetArgs = {
  body: ClassificationRulesetCreateBody;
};

export type MutationCreateIdentityArgs = {
  input: IdentityCreateBody;
};

export type MutationCreateIngestJobArgs = {
  input: CreateIngestJobInput;
};

export type MutationCreateJobChainArgs = {
  input: CreateJobChainInput;
};

export type MutationCreateServiceArgs = {
  input: ServiceCreateBody;
};

export type MutationCreateServiceFromIntegrationArgs = {
  input: CreateIntegrationInput;
};

export type MutationDeleteServiceIntegrationArgs = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type MutationDeleteUserArgs = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationRegisterArgs = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  orgDisplayName?: InputMaybe<Scalars['String']['input']>;
  orgSlug?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateIdentitiesArgs = {
  input: BulkUpdatesIdentitiesInput;
};

export type MutationUpdateIdentityArgs = {
  input: IdentityUpdateBody;
};

export type MutationUpdateOrgArgs = {
  input: OrgUpdateBody;
};

export type MutationUpdateOrgFeatureSwitchArgs = {
  input: OrgFeatureSwitchBody;
};

export type MutationUpdateServiceArgs = {
  input: ServiceUpdateBody;
};

/** Details for an oauth token object */
export type OAuthTokenDetails = AuthResourceDetails & {
  __typename?: 'OAuthTokenDetails';
  scopes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  serviceIdentityRef: Scalars['ObjectID']['output'];
  type: AuthResourceType;
};

/** Okta activity detail */
export type OktaActivityDetail = ActivityDetail & {
  __typename?: 'OktaActivityDetail';
  activityId: Scalars['String']['output'];
  actorIpAddress?: Maybe<Scalars['String']['output']>;
  displayMessage?: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  integrationId: IntegrationId;
  oktaTargets?: Maybe<Array<Maybe<OktaTarget>>>;
  oktaUserAltId?: Maybe<Scalars['String']['output']>;
  oktaUserDisplayName?: Maybe<Scalars['String']['output']>;
  oktaUserId?: Maybe<Scalars['String']['output']>;
  severity: Scalars['String']['output'];
};

/** Okta integration connection details */
export type OktaIntegration = Integration & {
  __typename?: 'OktaIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  status: IntegrationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** Okta targets associated with the activity log */
export type OktaTarget = {
  __typename?: 'OktaTarget';
  alternateId?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** An object to metadata about an Organization’s onboarding process. */
export type Onboarding = {
  __typename?: 'Onboarding';
  connectedIDP: Scalars['Boolean']['output'];
};

/** A singular tenant in Natoma. */
export type Org = {
  __typename?: 'Org';
  _id: Scalars['ObjectID']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  /** Feature switch overrides for the org */
  featureSwitchOverrides: Scalars['FeatureSwitchOverride']['output'];
  /** Onboarding progress for an Organization. */
  onboarding?: Maybe<Onboarding>;
  slug: Scalars['String']['output'];
  status: OrgStatus;
  /** When the Org was tombstoned */
  tombstonedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrgFeatureSwitchBody = {
  featureSwitchOverrides: Scalars['FeatureSwitchOverride']['input'];
};

/** The status of an organization */
export enum OrgStatus {
  Active = 'ACTIVE',
  Tombstoned = 'TOMBSTONED'
}

/** Input body fo rupdating an Identity object. */
export type OrgUpdateBody = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrgStatus>;
};

/** A single permission attribute */
export type Permission = {
  __typename?: 'Permission';
  action: Scalars['StringOrArray']['output'];
  effect?: Maybe<PermissionEffect>;
  filter?: Maybe<Scalars['StringOrArray']['output']>;
};

/** ALLOW or DENY a specific permission action */
export enum PermissionEffect {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

/** Details for a permission set object */
export type PermissionSetDetails = AuthResourceDetails & {
  __typename?: 'PermissionSetDetails';
  permissions?: Maybe<Array<Maybe<Permission>>>;
  type: AuthResourceType;
};

export type Query = {
  __typename?: 'Query';
  /** Get a list of activities */
  activities: Array<Activity>;
  /** Fetches the count of activities by a service/identity filter */
  activitiesCount: ActivitiesCount;
  /** Get details about an individual activity. */
  activityDetails?: Maybe<Activity>;
  /** Get a list of alerts within the current org */
  alerts: Array<Alert>;
  allOrgs: Array<Org>;
  /** Fetch details about one auth resource. */
  authResourceDetails?: Maybe<AuthResource>;
  /** Get a list of auth resources */
  authResources: Array<AuthResource>;
  classificationRuleset?: Maybe<ClassificationRuleset>;
  getAlertDetails: Alert;
  getIngestJob: Job;
  /** Fetches the number of integrated applications by their integrationId */
  getIntegrationCounts: Array<IntegrationCount>;
  /** Get a list of identities within the current org. These represent Non-Human identities. */
  identities: Array<Identity>;
  /** Fetch details about one identity. */
  identityDetails?: Maybe<Identity>;
  /** Get a list of ingestion jobs within the current org. */
  ingestjobs: Array<Job>;
  /** Get the latest job chain for a given service within the current org */
  latestJobChain: JobChain;
  me: MeResponse;
  myOrgs: Array<Org>;
  org: Org;
  /** Fetch all permissions for an authenticator */
  permissions?: Maybe<Array<Maybe<AuthResource>>>;
  /** Gets details about an individual service. */
  serviceDetails?: Maybe<Service>;
  /** Get a list of service identities within the current org. */
  serviceIdentities: Array<ServiceIdentity>;
  /** Get one service identity within the current org. */
  serviceIdentity?: Maybe<ServiceIdentity>;
  services?: Maybe<Array<Service>>;
  user: User;
  users?: Maybe<Array<User>>;
};

export type QueryActivitiesArgs = {
  identityId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  sort?: InputMaybe<GetActivitiesSort>;
};

export type QueryActivitiesCountArgs = {
  identityId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
};

export type QueryActivityDetailsArgs = {
  _id: Scalars['ObjectID']['input'];
};

export type QueryAlertsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  owners?: Array<Scalars['ObjectID']['input']>;
  severities?: Array<AlertSeverity>;
};

export type QueryAllOrgsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GetOrgsSort>;
};

export type QueryAuthResourceDetailsArgs = {
  _id: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAuthResourcesArgs = {
  authResourceType?: InputMaybe<Array<AuthResourceType>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  sort?: InputMaybe<GetAuthResourcesSort>;
  status?: InputMaybe<Array<AuthResourceStatus>>;
};

export type QueryGetAlertDetailsArgs = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type QueryGetIngestJobArgs = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type QueryGetIntegrationCountsArgs = {
  integrationId?: InputMaybe<IntegrationId>;
};

export type QueryIdentitiesArgs = {
  accountType?: InputMaybe<Array<InputMaybe<IdentityAccountType>>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<IdentityScope>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  sort?: InputMaybe<GetIdentitiesSort>;
};

export type QueryIdentityDetailsArgs = {
  _id: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryIngestjobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  type?: InputMaybe<JobType>;
};

export type QueryLatestJobChainArgs = {
  serviceId: Scalars['ObjectID']['input'];
  type: JobChainType;
};

export type QueryOrgArgs = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type QueryPermissionsArgs = {
  authenticatorId: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serviceIdentityId: Scalars['ObjectID']['input'];
};

export type QueryServiceDetailsArgs = {
  _id: Scalars['ObjectID']['input'];
};

export type QueryServiceIdentitiesArgs = {
  authResourceTypes?: InputMaybe<Array<AuthResourceType>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nonHumanFlag?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GetServiceIdentitiesSort>;
};

export type QueryServiceIdentityArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryServicesArgs = {
  identityId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  isIntegration?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgId?: InputMaybe<Scalars['ObjectID']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GetUsersSort>;
  status?: InputMaybe<Array<InputMaybe<UserStatus>>>;
};

/** Details for a role object */
export type RoleDetails = AuthResourceDetails & {
  __typename?: 'RoleDetails';
  authResources: Array<Maybe<Scalars['ObjectID']['output']>>;
  externalType?: Maybe<Scalars['String']['output']>;
  type: AuthResourceType;
};

/** Salesforce connection details */
export type SalesforceIntegration = Integration & {
  __typename?: 'SalesforceIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  instanceUrl?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  status: IntegrationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** The representation of a third party app to which service identities and resources belong */
export type Service = {
  __typename?: 'Service';
  _id: Scalars['ObjectID']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  integration?: Maybe<Integration>;
  lastActivityIngest?: Maybe<Scalars['DateTime']['output']>;
  org: Org;
  owner?: Maybe<User>;
  serviceIdentities: Array<ServiceIdentity>;
  serviceIdentityCount: Scalars['Int']['output'];
  status: ServiceStatus;
};

/** The representation of a third party app to which service identities and resources belong */
export type ServiceServiceIdentitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Input body for creating a Service object */
export type ServiceCreateBody = {
  /** The display name for this service */
  displayName: Scalars['String']['input'];
};

/** Service-specific identity details for a given non-human identity */
export type ServiceIdentity = {
  __typename?: 'ServiceIdentity';
  _id: Scalars['ObjectID']['output'];
  authResources: Array<AuthResource>;
  externalId?: Maybe<Scalars['String']['output']>;
  identity: IdentityOrError;
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  org: Org;
  principal?: Maybe<IdentityPrincipal>;
  scope?: Maybe<ServiceIdentityScope>;
  service: Service;
  status: ServiceIdentityStatus;
};

/** Service-specific identity details for a given non-human identity */
export type ServiceIdentityAuthResourcesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetAuthResourcesSort>;
  start?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<AuthResourceType>>;
};

/** The scope of a service identity */
export enum ServiceIdentityScope {
  InScope = 'IN_SCOPE',
  OutOfScope = 'OUT_OF_SCOPE'
}

/** The status of a service-specific identity */
export enum ServiceIdentityStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Tombstoned = 'TOMBSTONED'
}

/** The type of owner for a service */
export enum ServiceOwnerType {
  User = 'USER'
}

/** The status of a service within the system */
export enum ServiceStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
  Tombstoned = 'TOMBSTONED'
}

/** Input body for updating a Service object */
export type ServiceUpdateBody = {
  /** ID of the service to update the type. */
  _id: Scalars['String']['input'];
  patch: ServiceUpdatePatch;
};

/** Patch object for updating an service */
export type ServiceUpdatePatch = {
  newScopes?: InputMaybe<Array<Scalars['String']['input']>>;
  ownerId?: InputMaybe<Scalars['ObjectID']['input']>;
};

/** Snowflake integration details */
export type SnowflakeIntegration = Integration & {
  __typename?: 'SnowflakeIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** A human user who is able to log in and act within Natoma. */
export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectID']['output'];
  /** User data from descope (used for authenticating users). */
  descope: DescopeData;
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  loginType: UserLoginType;
  org: Org;
  role: UserRole;
  status: UserStatus;
};

/** The type of login method used by a user */
export enum UserLoginType {
  Local = 'LOCAL',
  Sso = 'SSO'
}

/** The role of a user */
export enum UserRole {
  Admin = 'ADMIN'
}

/** The status of an organization */
export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invited = 'INVITED'
}

export type CreateClassificationRulesetMutationVariables = Exact<{
  body: ClassificationRulesetCreateBody;
}>;

export type CreateClassificationRulesetMutation = {
  __typename?: 'Mutation';
  createClassificationRuleset: {
    __typename?: 'ClassificationRuleset';
    _id: any;
    rules: Array<{
      __typename?: 'ClassificationRule';
      _id: any;
      description?: string | null;
      type: ClassificationRuleType;
      category: MatchType;
      property: string;
      rule: string;
      targetType: ClassificationRuleTargetType;
      scope: IdentityScope;
    } | null>;
  };
};

export type CreateIngestJobMutationVariables = Exact<{
  input: CreateIngestJobInput;
}>;

export type CreateIngestJobMutation = {
  __typename?: 'Mutation';
  createIngestJob: {
    __typename?: 'Job';
    _id: any;
    completedAt?: any | null;
    queuedAt: any;
    startedAt?: any | null;
    status: JobStatus;
    type: JobType;
    triggeredBy?: { __typename?: 'User'; _id: any } | null;
  };
};

export type CreateJobChainMutationVariables = Exact<{
  input: CreateJobChainInput;
}>;

export type CreateJobChainMutation = {
  __typename?: 'Mutation';
  createJobChain: Array<{
    __typename?: 'Job';
    _id: any;
    completedAt?: any | null;
    queuedAt: any;
    startedAt?: any | null;
    status: JobStatus;
    type: JobType;
    triggeredBy?: { __typename?: 'User'; _id: any } | null;
  }>;
};

export type CreateServiceFromIntegrationMutationVariables = Exact<{
  input: CreateIntegrationInput;
}>;

export type CreateServiceFromIntegrationMutation = {
  __typename?: 'Mutation';
  createServiceFromIntegration: {
    __typename?: 'Service';
    _id: any;
    status: ServiceStatus;
    integration?:
      | { __typename: 'AWSIntegration'; status: IntegrationStatus }
      | { __typename: 'ActiveDirectoryIntegration'; status: IntegrationStatus }
      | { __typename: 'EntraIntegration'; status: IntegrationStatus }
      | { __typename: 'GithubIntegration'; status: IntegrationStatus }
      | {
          __typename: 'GoogleWorkspaceIntegration';
          scope?: string | null;
          status: IntegrationStatus;
        }
      | {
          __typename: 'OktaIntegration';
          domain?: string | null;
          status: IntegrationStatus;
        }
      | { __typename: 'SalesforceIntegration'; status: IntegrationStatus }
      | { __typename: 'SnowflakeIntegration'; status: IntegrationStatus }
      | null;
  };
};

export type DeleteServiceIntegrationMutationVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type DeleteServiceIntegrationMutation = {
  __typename?: 'Mutation';
  deleteServiceIntegration?: {
    __typename?: 'DeleteResult';
    _id?: string | null;
    type?: string | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: {
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
    role: UserRole;
    org: { __typename?: 'Org'; _id: any };
  };
};

export type OrgRegistrationFieldsFragment = {
  __typename?: 'Org';
  _id: any;
  displayName?: string | null;
  slug: string;
  status: OrgStatus;
};

export type UserRegistrationFieldsFragment = {
  __typename?: 'User';
  _id: any;
  email: string;
  displayName?: string | null;
  status: UserStatus;
  org: { __typename?: 'Org'; _id: any };
  descope: { __typename?: 'DescopeData'; userId?: string | null };
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteUser: {
    __typename?: 'User';
    _id: any;
    email: string;
    displayName?: string | null;
    status: UserStatus;
    role: UserRole;
    descope: { __typename?: 'DescopeData'; userId?: string | null };
    org: { __typename?: 'Org'; _id: any };
  };
};

export type RegisterMutationVariables = Exact<{
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  orgDisplayName?: InputMaybe<Scalars['String']['input']>;
  orgSlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register: {
    __typename?: 'MeResponse';
    currentOrg?: {
      __typename?: 'Org';
      _id: any;
      displayName?: string | null;
      slug: string;
      status: OrgStatus;
    } | null;
    currentUser?: {
      __typename?: 'User';
      _id: any;
      email: string;
      displayName?: string | null;
      status: UserStatus;
      org: { __typename?: 'Org'; _id: any };
      descope: { __typename?: 'DescopeData'; userId?: string | null };
    } | null;
  };
};

export type UpdateIdentitiesMutationVariables = Exact<{
  input: BulkUpdatesIdentitiesInput;
}>;

export type UpdateIdentitiesMutation = {
  __typename?: 'Mutation';
  updateIdentities: Array<{
    __typename?: 'Identity';
    _id: any;
    email?: string | null;
    displayName?: string | null;
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
    } | null;
  }>;
};

export type UpdateIdentityMutationVariables = Exact<{
  input: IdentityUpdateBody;
}>;

export type UpdateIdentityMutation = {
  __typename?: 'Mutation';
  updateIdentity: {
    __typename?: 'Identity';
    _id: any;
    email?: string | null;
    displayName?: string | null;
    org: {
      __typename?: 'Org';
      _id: any;
      displayName?: string | null;
      slug: string;
    };
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
      org: {
        __typename?: 'Org';
        _id: any;
        displayName?: string | null;
        slug: string;
      };
    } | null;
  };
};

export type UpdateOrgMutationVariables = Exact<{
  input: OrgUpdateBody;
}>;

export type UpdateOrgMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    _id: any;
    displayName?: string | null;
    status: OrgStatus;
    onboarding?: { __typename?: 'Onboarding'; connectedIDP: boolean } | null;
  };
};

export type CurrentOrgFieldsFragment = {
  __typename?: 'Org';
  _id: any;
  slug: string;
  displayName?: string | null;
  status: OrgStatus;
  featureSwitchOverrides: any;
  onboarding?: { __typename?: 'Onboarding'; connectedIDP: boolean } | null;
};

export type CurrentUserFieldsFragment = {
  __typename?: 'User';
  _id: any;
  email: string;
  displayName?: string | null;
  status: UserStatus;
};

export type IngestJobFieldsFragment = {
  __typename?: 'Job';
  _id: any;
  completedAt?: any | null;
  queuedAt: any;
  startedAt?: any | null;
  status: JobStatus;
  type: JobType;
  error?: string | null;
  missingScopes?: Array<string> | null;
  triggeredBy?: { __typename?: 'User'; _id: any } | null;
  org: { __typename?: 'Org'; _id: any };
  context?: {
    __typename?: 'JobContext';
    type?: JobContextType | null;
    ref?: any | null;
    service?: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      integration?:
        | {
            __typename?: 'AWSIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'EntraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GithubIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'OktaIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SalesforceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SnowflakeIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | null;
    } | null;
  } | null;
  processingMetadata?: {
    __typename?: 'IngestJobProcessingMetadata';
    processedIdentities?: number | null;
    expectedIdentities?: number | null;
  } | null;
};

export type IdentityFieldFragment = {
  __typename?: 'Identity';
  _id: any;
  displayName?: string | null;
  owner?: {
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
  } | null;
  classification?: {
    __typename?: 'Classification';
    accountType?: IdentityAccountType | null;
    scope?: IdentityScope | null;
  } | null;
  serviceIdentities: Array<{
    __typename?: 'ServiceIdentity';
    _id: any;
    service: {
      __typename?: 'Service';
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    };
  }>;
};

export type IdentityFieldForListFragment = {
  __typename?: 'Identity';
  _id: any;
  displayName?: string | null;
  owner?: {
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
  } | null;
};

export type PermissionSetDetailFieldFragment = {
  __typename?: 'PermissionSetDetails';
  permissions?: Array<{
    __typename?: 'Permission';
    effect?: PermissionEffect | null;
    action: any;
    filter?: any | null;
  } | null> | null;
};

export type GroupDetailFieldFragment = {
  __typename?: 'GroupDetails';
  authResources: Array<any | null>;
};

export type OauthTokenDetailFieldFragment = {
  __typename?: 'OAuthTokenDetails';
  serviceIdentityRef: any;
  scopes?: Array<string | null> | null;
};

export type RoleDetailFieldFragment = {
  __typename?: 'RoleDetails';
  authResources: Array<any | null>;
  externalType?: string | null;
};

export type GetActivitiesQueryVariables = Exact<{
  identityId: Array<Scalars['ObjectID']['input']>;
  serviceId: Array<Scalars['ObjectID']['input']>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<GetActivitiesSort>;
}>;

export type GetActivitiesQuery = {
  __typename?: 'Query';
  activities: Array<{
    __typename?: 'Activity';
    _id: any;
    actorType: ActorType;
    actorName?: string | null;
    actionType: string;
    timestamp: any;
    org: { __typename?: 'Org'; _id: any };
    service: { __typename?: 'Service'; _id: any };
    identities?: Array<{ __typename?: 'ServiceIdentity'; _id: any }> | null;
  }>;
  activitiesCount: { __typename?: 'ActivitiesCount'; count: number };
};

export type GetActivityDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type GetActivityDetailsQuery = {
  __typename?: 'Query';
  activityDetails?: {
    __typename?: 'Activity';
    _id: any;
    org: { __typename?: 'Org'; _id: any };
    detail?:
      | {
          __typename?: 'GenericActivityDetail';
          integrationId: IntegrationId;
          activityId: string;
        }
      | {
          __typename?: 'GithubActivityDetail';
          integrationId: IntegrationId;
          activityId: string;
        }
      | {
          __typename?: 'GoogleWorkspaceActivityDetail';
          integrationId: IntegrationId;
          activityId: string;
        }
      | {
          __typename?: 'OktaActivityDetail';
          oktaUserId?: string | null;
          oktaUserDisplayName?: string | null;
          displayMessage?: string | null;
          eventType: string;
          severity: string;
          integrationId: IntegrationId;
          activityId: string;
        }
      | null;
  } | null;
};

export type GetAuthResourceDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type GetAuthResourceDetailsQuery = {
  __typename?: 'Query';
  authResourceDetails?: {
    __typename?: 'AuthResource';
    _id: any;
    status: AuthResourceStatus;
    externalId: string;
    name?: string | null;
    lastActivity?: any | null;
    service: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    };
    details?:
      | { __typename?: 'APITokenDetails'; type: AuthResourceType }
      | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
      | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
      | { __typename?: 'GroupDetails'; type: AuthResourceType }
      | {
          __typename?: 'OAuthTokenDetails';
          type: AuthResourceType;
          serviceIdentityRef: any;
          scopes?: Array<string | null> | null;
        }
      | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
      | { __typename?: 'RoleDetails'; type: AuthResourceType }
      | null;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      _id: any;
      principal?: {
        __typename?: 'IdentityPrincipal';
        principal: string;
      } | null;
    }>;
  } | null;
};

export type GetAuthResourcesQueryVariables = Exact<{
  authResourceType?: InputMaybe<Array<AuthResourceType>>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  status?: InputMaybe<Array<AuthResourceStatus>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAuthResourcesQuery = {
  __typename?: 'Query';
  authResources: Array<{
    __typename?: 'AuthResource';
    _id: any;
    status: AuthResourceStatus;
    externalId: string;
    name?: string | null;
    service: {
      __typename?: 'Service';
      _id: any;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    };
    details?:
      | { __typename?: 'APITokenDetails'; type: AuthResourceType }
      | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
      | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
      | { __typename?: 'GroupDetails'; type: AuthResourceType }
      | { __typename?: 'OAuthTokenDetails'; type: AuthResourceType }
      | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
      | { __typename?: 'RoleDetails'; type: AuthResourceType }
      | null;
  }>;
};

export type GetClassificationRulesetQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetClassificationRulesetQuery = {
  __typename?: 'Query';
  classificationRuleset?: {
    __typename?: 'ClassificationRuleset';
    _id: any;
    createdAt: any;
    updatedAt: any;
    rules: Array<{
      __typename?: 'ClassificationRule';
      _id: any;
      description?: string | null;
      type: ClassificationRuleType;
      category: MatchType;
      property: string;
      rule: string;
      targetType: ClassificationRuleTargetType;
      scope: IdentityScope;
    } | null>;
  } | null;
};

export type GetIdentitiesQueryVariables = Exact<{
  serviceId: Array<Scalars['ObjectID']['input']>;
  integrationId: Array<IntegrationId>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  sort: GetIdentitiesSort;
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetIdentitiesQuery = {
  __typename?: 'Query';
  identities: Array<{
    __typename?: 'Identity';
    _id: any;
    displayName?: string | null;
    servicesCount?: number | null;
    status: IdentityStatus;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      service: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | null;
      };
    }>;
  }>;
};

export type GetAllIdentitiesQueryVariables = Exact<{
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetIdentitiesSort>;
  q?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<Array<InputMaybe<IdentityAccountType>>>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  scope?: InputMaybe<IdentityScope>;
}>;

export type GetAllIdentitiesQuery = {
  __typename?: 'Query';
  identities: Array<{
    __typename?: 'Identity';
    _id: any;
    displayName?: string | null;
    email?: string | null;
    status: IdentityStatus;
    servicesCount?: number | null;
    classification?: {
      __typename?: 'Classification';
      accountType?: IdentityAccountType | null;
      decidedAt: any;
      scope?: IdentityScope | null;
    } | null;
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
      status: UserStatus;
    } | null;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      _id: any;
      externalId?: string | null;
      service: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | null;
      };
      authResources: Array<{
        __typename?: 'AuthResource';
        _id: any;
        name?: string | null;
        externalId: string;
        details?:
          | { __typename?: 'APITokenDetails'; type: AuthResourceType }
          | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
          | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
          | { __typename?: 'GroupDetails'; type: AuthResourceType }
          | { __typename?: 'OAuthTokenDetails'; type: AuthResourceType }
          | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
          | { __typename?: 'RoleDetails'; type: AuthResourceType }
          | null;
      }>;
    }>;
  }>;
};

export type GetIdentityDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetIdentityDetailsQuery = {
  __typename?: 'Query';
  identityDetails?: {
    __typename?: 'Identity';
    _id: any;
    displayName?: string | null;
    servicesCount?: number | null;
    status: IdentityStatus;
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
      status: UserStatus;
    } | null;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      _id: any;
      status: ServiceIdentityStatus;
      service: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        status: ServiceStatus;
        lastActivityIngest?: any | null;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | null;
      };
    }>;
    classification?: {
      __typename?: 'Classification';
      decidedAt: any;
      accountType?: IdentityAccountType | null;
    } | null;
  } | null;
};

export type IngestjobsQueryVariables = Exact<{
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  type?: InputMaybe<JobType>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export type IngestjobsQuery = {
  __typename?: 'Query';
  ingestjobs: Array<{
    __typename?: 'Job';
    _id: any;
    completedAt?: any | null;
    queuedAt: any;
    startedAt?: any | null;
    status: JobStatus;
    type: JobType;
    error?: string | null;
    missingScopes?: Array<string> | null;
    triggeredBy?: { __typename?: 'User'; _id: any } | null;
    org: { __typename?: 'Org'; _id: any };
    context?: {
      __typename?: 'JobContext';
      type?: JobContextType | null;
      ref?: any | null;
      service?: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | null;
      } | null;
    } | null;
    processingMetadata?: {
      __typename?: 'IngestJobProcessingMetadata';
      processedIdentities?: number | null;
      expectedIdentities?: number | null;
    } | null;
  }>;
};

export type IngestjobQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type IngestjobQuery = {
  __typename?: 'Query';
  getIngestJob: {
    __typename?: 'Job';
    _id: any;
    completedAt?: any | null;
    queuedAt: any;
    startedAt?: any | null;
    status: JobStatus;
    type: JobType;
    error?: string | null;
    missingScopes?: Array<string> | null;
    triggeredBy?: { __typename?: 'User'; _id: any } | null;
    org: { __typename?: 'Org'; _id: any };
    context?: {
      __typename?: 'JobContext';
      type?: JobContextType | null;
      ref?: any | null;
      service?: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | null;
      } | null;
    } | null;
    processingMetadata?: {
      __typename?: 'IngestJobProcessingMetadata';
      processedIdentities?: number | null;
      expectedIdentities?: number | null;
    } | null;
  };
};

export type GetIntegrationCountsQueryVariables = Exact<{
  integrationId?: InputMaybe<IntegrationId>;
}>;

export type GetIntegrationCountsQuery = {
  __typename?: 'Query';
  getIntegrationCounts: Array<{
    __typename?: 'IntegrationCount';
    integrationId: IntegrationId;
    count: number;
  }>;
};

export type LatestJobChainQueryVariables = Exact<{
  serviceId: Scalars['ObjectID']['input'];
  type: JobChainType;
}>;

export type LatestJobChainQuery = {
  __typename?: 'Query';
  latestJobChain: {
    __typename?: 'JobChain';
    _id: any;
    startedAt?: any | null;
    completedAt?: any | null;
    status: JobStatus;
    error?: string | null;
    missingScopes?: Array<string> | null;
  };
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'MeResponse';
    currentOrg?: {
      __typename?: 'Org';
      _id: any;
      slug: string;
      displayName?: string | null;
      status: OrgStatus;
      featureSwitchOverrides: any;
      onboarding?: { __typename?: 'Onboarding'; connectedIDP: boolean } | null;
    } | null;
    currentUser?: {
      __typename?: 'User';
      _id: any;
      email: string;
      displayName?: string | null;
      status: UserStatus;
    } | null;
  };
};

export type GetMyOrgsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyOrgsQuery = {
  __typename?: 'Query';
  myOrgs: Array<{
    __typename?: 'Org';
    _id: any;
    slug: string;
    displayName?: string | null;
    status: OrgStatus;
    featureSwitchOverrides: any;
    onboarding?: { __typename?: 'Onboarding'; connectedIDP: boolean } | null;
  }>;
};

export type GetPermissionsQueryVariables = Exact<{
  serviceIdentityId: Scalars['ObjectID']['input'];
  authenticatorId: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPermissionsQuery = {
  __typename?: 'Query';
  permissions?: Array<{
    __typename?: 'AuthResource';
    _id: any;
    externalId: string;
    name?: string | null;
    createdOn?: any | null;
    lastActivity?: any | null;
    details?:
      | { __typename?: 'APITokenDetails'; type: AuthResourceType }
      | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
      | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
      | { __typename?: 'GroupDetails'; type: AuthResourceType }
      | { __typename?: 'OAuthTokenDetails'; type: AuthResourceType }
      | {
          __typename?: 'PermissionSetDetails';
          type: AuthResourceType;
          permissions?: Array<{
            __typename?: 'Permission';
            effect?: PermissionEffect | null;
            action: any;
            filter?: any | null;
          } | null> | null;
        }
      | { __typename?: 'RoleDetails'; type: AuthResourceType }
      | null;
  } | null> | null;
};

export type GetServiceDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetServiceDetailsQuery = {
  __typename?: 'Query';
  serviceDetails?: {
    __typename?: 'Service';
    _id: any;
    displayName: string;
    status: ServiceStatus;
    integration?:
      | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
      | {
          __typename?: 'ActiveDirectoryIntegration';
          integrationId: IntegrationId;
        }
      | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
      | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
      | {
          __typename?: 'GoogleWorkspaceIntegration';
          integrationId: IntegrationId;
        }
      | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
      | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
      | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
      | null;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      _id: any;
      status: ServiceIdentityStatus;
      identity:
        | { __typename?: 'Error'; message: string }
        | {
            __typename?: 'Identity';
            _id: any;
            displayName?: string | null;
            email?: string | null;
            classification?: {
              __typename?: 'Classification';
              decidedAt: any;
              accountType?: IdentityAccountType | null;
            } | null;
            owner?: {
              __typename?: 'User';
              _id: any;
              displayName?: string | null;
              email: string;
              status: UserStatus;
            } | null;
          };
    }>;
  } | null;
};

export type GetServiceIdentityCountsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type GetServiceIdentityCountsQuery = {
  __typename?: 'Query';
  serviceDetails?: {
    __typename?: 'Service';
    serviceIdentityCount: number;
  } | null;
};

export type GetAllServiceIdentitiesQueryVariables = Exact<{
  integrationId?: InputMaybe<Array<IntegrationId>>;
  authResourceTypes?: InputMaybe<Array<AuthResourceType>>;
  owners?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  nonHumanFlag?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetServiceIdentitiesSort>;
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAllServiceIdentitiesQuery = {
  __typename?: 'Query';
  serviceIdentities: Array<{
    __typename?: 'ServiceIdentity';
    _id: any;
    externalId?: string | null;
    service: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      integration?:
        | {
            __typename?: 'AWSIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'EntraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GithubIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'OktaIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SalesforceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SnowflakeIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | null;
    };
    identity:
      | { __typename?: 'Error' }
      | {
          __typename?: 'Identity';
          _id: any;
          displayName?: string | null;
          owner?: {
            __typename?: 'User';
            _id: any;
            displayName?: string | null;
            email: string;
            status: UserStatus;
          } | null;
        };
    authResources: Array<{
      __typename?: 'AuthResource';
      _id: any;
      name?: string | null;
      externalId: string;
      details?:
        | { __typename?: 'APITokenDetails'; type: AuthResourceType }
        | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
        | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
        | { __typename?: 'GroupDetails'; type: AuthResourceType }
        | { __typename?: 'OAuthTokenDetails'; type: AuthResourceType }
        | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
        | { __typename?: 'RoleDetails'; type: AuthResourceType }
        | null;
    }>;
  }>;
};

export type GetServiceIdentityAuthResourcesQueryVariables = Exact<{
  id: Scalars['ObjectID']['input'];
  start?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<AuthResourceType>>;
  sort?: InputMaybe<GetAuthResourcesSort>;
}>;

export type GetServiceIdentityAuthResourcesQuery = {
  __typename?: 'Query';
  serviceIdentity?: {
    __typename?: 'ServiceIdentity';
    _id: any;
    authResources: Array<{
      __typename?: 'AuthResource';
      _id: any;
      externalId: string;
      name?: string | null;
      createdOn?: any | null;
      lastActivity?: any | null;
      details?:
        | { __typename?: 'APITokenDetails'; type: AuthResourceType }
        | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
        | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
        | { __typename?: 'GroupDetails'; type: AuthResourceType }
        | { __typename?: 'OAuthTokenDetails'; type: AuthResourceType }
        | {
            __typename?: 'PermissionSetDetails';
            type: AuthResourceType;
            permissions?: Array<{
              __typename?: 'Permission';
              effect?: PermissionEffect | null;
              action: any;
              filter?: any | null;
            } | null> | null;
          }
        | { __typename?: 'RoleDetails'; type: AuthResourceType }
        | null;
    }>;
  } | null;
};

export type GetServiceIdentityDetailsQueryVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;

export type GetServiceIdentityDetailsQuery = {
  __typename?: 'Query';
  serviceIdentity?: {
    __typename?: 'ServiceIdentity';
    _id: any;
    externalId?: string | null;
    lastActivity?: any | null;
    service: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      integration?:
        | {
            __typename?: 'AWSIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'EntraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GithubIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'OktaIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SalesforceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SnowflakeIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | null;
    };
    identity:
      | { __typename?: 'Error' }
      | {
          __typename?: 'Identity';
          _id: any;
          displayName?: string | null;
          owner?: {
            __typename?: 'User';
            _id: any;
            displayName?: string | null;
            email: string;
            status: UserStatus;
          } | null;
          classification?: {
            __typename?: 'Classification';
            accountType?: IdentityAccountType | null;
            scope?: IdentityScope | null;
          } | null;
          serviceIdentities: Array<{
            __typename?: 'ServiceIdentity';
            _id: any;
            service: {
              __typename?: 'Service';
              integration?:
                | {
                    __typename?: 'AWSIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'ActiveDirectoryIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'EntraIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'GithubIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'GoogleWorkspaceIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'OktaIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'SalesforceIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'SnowflakeIntegration';
                    integrationId: IntegrationId;
                  }
                | null;
            };
          }>;
        };
  } | null;
};

export type GetServicesQueryVariables = Exact<{
  identityId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  isIntegration?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetServicesQuery = {
  __typename?: 'Query';
  services?: Array<{
    __typename?: 'Service';
    _id: any;
    displayName: string;
    status: ServiceStatus;
    serviceIdentityCount: number;
    integration?:
      | {
          __typename?: 'AWSIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
        }
      | {
          __typename?: 'ActiveDirectoryIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
        }
      | {
          __typename?: 'EntraIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
        }
      | {
          __typename?: 'GithubIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
        }
      | {
          __typename?: 'GoogleWorkspaceIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
        }
      | {
          __typename?: 'OktaIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
        }
      | {
          __typename?: 'SalesforceIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
        }
      | {
          __typename?: 'SnowflakeIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
        }
      | null;
  }> | null;
};

export type GetUsersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GetUsersSort>;
  status?: InputMaybe<Array<InputMaybe<UserStatus>>>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
    role: UserRole;
    org: { __typename?: 'Org'; _id: any };
  }> | null;
};

export const OrgRegistrationFieldsFragmentDoc = gql`
  fragment OrgRegistrationFields on Org {
    _id
    displayName
    slug
    status
  }
`;
export const UserRegistrationFieldsFragmentDoc = gql`
  fragment UserRegistrationFields on User {
    _id
    org {
      _id
    }
    email
    displayName
    status
    descope {
      userId
    }
  }
`;
export const CurrentOrgFieldsFragmentDoc = gql`
  fragment CurrentOrgFields on Org {
    _id
    slug
    displayName
    status
    onboarding {
      connectedIDP
    }
    featureSwitchOverrides
  }
`;
export const CurrentUserFieldsFragmentDoc = gql`
  fragment CurrentUserFields on User {
    _id
    email
    displayName
    status
  }
`;
export const IngestJobFieldsFragmentDoc = gql`
  fragment IngestJobFields on Job {
    _id
    completedAt
    queuedAt
    startedAt
    status
    triggeredBy {
      _id
    }
    type
    org {
      _id
    }
    context {
      type
      ref
      service {
        _id
        displayName
        integration {
          integrationId
          description
        }
      }
    }
    processingMetadata {
      processedIdentities
      expectedIdentities
    }
    error
    missingScopes
  }
`;
export const IdentityFieldFragmentDoc = gql`
  fragment IdentityField on Identity {
    _id
    displayName
    owner {
      _id
      displayName
      email
      status
    }
    classification {
      accountType
      scope
    }
    serviceIdentities {
      _id
      service {
        integration {
          integrationId
        }
      }
    }
  }
`;
export const IdentityFieldForListFragmentDoc = gql`
  fragment IdentityFieldForList on Identity {
    _id
    displayName
    owner {
      _id
      displayName
      email
      status
    }
  }
`;
export const PermissionSetDetailFieldFragmentDoc = gql`
  fragment PermissionSetDetailField on PermissionSetDetails {
    permissions {
      effect
      action
      filter
    }
  }
`;
export const GroupDetailFieldFragmentDoc = gql`
  fragment GroupDetailField on GroupDetails {
    authResources
  }
`;
export const OauthTokenDetailFieldFragmentDoc = gql`
  fragment OauthTokenDetailField on OAuthTokenDetails {
    serviceIdentityRef
    scopes
  }
`;
export const RoleDetailFieldFragmentDoc = gql`
  fragment RoleDetailField on RoleDetails {
    authResources
    externalType
  }
`;
export const CreateClassificationRulesetDocument = gql`
  mutation createClassificationRuleset(
    $body: ClassificationRulesetCreateBody!
  ) {
    createClassificationRuleset(body: $body) {
      _id
      rules {
        _id
        description
        type
        category
        property
        rule
        targetType
        scope
      }
    }
  }
`;
export type CreateClassificationRulesetMutationFn = Apollo.MutationFunction<
  CreateClassificationRulesetMutation,
  CreateClassificationRulesetMutationVariables
>;

/**
 * __useCreateClassificationRulesetMutation__
 *
 * To run a mutation, you first call `useCreateClassificationRulesetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassificationRulesetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassificationRulesetMutation, { data, loading, error }] = useCreateClassificationRulesetMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateClassificationRulesetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClassificationRulesetMutation,
    CreateClassificationRulesetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClassificationRulesetMutation,
    CreateClassificationRulesetMutationVariables
  >(CreateClassificationRulesetDocument, options);
}
export type CreateClassificationRulesetMutationHookResult = ReturnType<
  typeof useCreateClassificationRulesetMutation
>;
export type CreateClassificationRulesetMutationResult =
  Apollo.MutationResult<CreateClassificationRulesetMutation>;
export type CreateClassificationRulesetMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClassificationRulesetMutation,
    CreateClassificationRulesetMutationVariables
  >;
export const CreateIngestJobDocument = gql`
  mutation createIngestJob($input: CreateIngestJobInput!) {
    createIngestJob(input: $input) {
      _id
      completedAt
      queuedAt
      startedAt
      status
      triggeredBy {
        _id
      }
      type
    }
  }
`;
export type CreateIngestJobMutationFn = Apollo.MutationFunction<
  CreateIngestJobMutation,
  CreateIngestJobMutationVariables
>;

/**
 * __useCreateIngestJobMutation__
 *
 * To run a mutation, you first call `useCreateIngestJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIngestJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIngestJobMutation, { data, loading, error }] = useCreateIngestJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIngestJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIngestJobMutation,
    CreateIngestJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIngestJobMutation,
    CreateIngestJobMutationVariables
  >(CreateIngestJobDocument, options);
}
export type CreateIngestJobMutationHookResult = ReturnType<
  typeof useCreateIngestJobMutation
>;
export type CreateIngestJobMutationResult =
  Apollo.MutationResult<CreateIngestJobMutation>;
export type CreateIngestJobMutationOptions = Apollo.BaseMutationOptions<
  CreateIngestJobMutation,
  CreateIngestJobMutationVariables
>;
export const CreateJobChainDocument = gql`
  mutation createJobChain($input: CreateJobChainInput!) {
    createJobChain(input: $input) {
      _id
      completedAt
      queuedAt
      startedAt
      status
      triggeredBy {
        _id
      }
      type
    }
  }
`;
export type CreateJobChainMutationFn = Apollo.MutationFunction<
  CreateJobChainMutation,
  CreateJobChainMutationVariables
>;

/**
 * __useCreateJobChainMutation__
 *
 * To run a mutation, you first call `useCreateJobChainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobChainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobChainMutation, { data, loading, error }] = useCreateJobChainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobChainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJobChainMutation,
    CreateJobChainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateJobChainMutation,
    CreateJobChainMutationVariables
  >(CreateJobChainDocument, options);
}
export type CreateJobChainMutationHookResult = ReturnType<
  typeof useCreateJobChainMutation
>;
export type CreateJobChainMutationResult =
  Apollo.MutationResult<CreateJobChainMutation>;
export type CreateJobChainMutationOptions = Apollo.BaseMutationOptions<
  CreateJobChainMutation,
  CreateJobChainMutationVariables
>;
export const CreateServiceFromIntegrationDocument = gql`
  mutation createServiceFromIntegration($input: CreateIntegrationInput!) {
    createServiceFromIntegration(input: $input) {
      _id
      status
      integration {
        __typename
        status
        ... on OktaIntegration {
          domain
        }
        ... on GoogleWorkspaceIntegration {
          scope
        }
      }
    }
  }
`;
export type CreateServiceFromIntegrationMutationFn = Apollo.MutationFunction<
  CreateServiceFromIntegrationMutation,
  CreateServiceFromIntegrationMutationVariables
>;

/**
 * __useCreateServiceFromIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateServiceFromIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceFromIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceFromIntegrationMutation, { data, loading, error }] = useCreateServiceFromIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceFromIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceFromIntegrationMutation,
    CreateServiceFromIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceFromIntegrationMutation,
    CreateServiceFromIntegrationMutationVariables
  >(CreateServiceFromIntegrationDocument, options);
}
export type CreateServiceFromIntegrationMutationHookResult = ReturnType<
  typeof useCreateServiceFromIntegrationMutation
>;
export type CreateServiceFromIntegrationMutationResult =
  Apollo.MutationResult<CreateServiceFromIntegrationMutation>;
export type CreateServiceFromIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateServiceFromIntegrationMutation,
    CreateServiceFromIntegrationMutationVariables
  >;
export const DeleteServiceIntegrationDocument = gql`
  mutation deleteServiceIntegration($_id: ObjectID!) {
    deleteServiceIntegration(_id: $_id) {
      _id
      type
    }
  }
`;
export type DeleteServiceIntegrationMutationFn = Apollo.MutationFunction<
  DeleteServiceIntegrationMutation,
  DeleteServiceIntegrationMutationVariables
>;

/**
 * __useDeleteServiceIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteServiceIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceIntegrationMutation, { data, loading, error }] = useDeleteServiceIntegrationMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeleteServiceIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceIntegrationMutation,
    DeleteServiceIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceIntegrationMutation,
    DeleteServiceIntegrationMutationVariables
  >(DeleteServiceIntegrationDocument, options);
}
export type DeleteServiceIntegrationMutationHookResult = ReturnType<
  typeof useDeleteServiceIntegrationMutation
>;
export type DeleteServiceIntegrationMutationResult =
  Apollo.MutationResult<DeleteServiceIntegrationMutation>;
export type DeleteServiceIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteServiceIntegrationMutation,
    DeleteServiceIntegrationMutationVariables
  >;
export const DeleteUserDocument = gql`
  mutation deleteUser($_id: ObjectID!) {
    deleteUser(_id: $_id) {
      _id
      org {
        _id
      }
      displayName
      email
      status
      role
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const InviteUserDocument = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      _id
      email
      displayName
      status
      descope {
        userId
      }
      role
      org {
        _id
      }
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserMutation,
    InviteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<
  typeof useInviteUserMutation
>;
export type InviteUserMutationResult =
  Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const RegisterDocument = gql`
  mutation register(
    $displayName: String
    $email: String
    $orgDisplayName: String
    $orgSlug: String
  ) {
    register(
      displayName: $displayName
      email: $email
      orgDisplayName: $orgDisplayName
      orgSlug: $orgSlug
    ) {
      currentOrg {
        ...OrgRegistrationFields
      }
      currentUser {
        ...UserRegistrationFields
      }
    }
  }
  ${OrgRegistrationFieldsFragmentDoc}
  ${UserRegistrationFieldsFragmentDoc}
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      email: // value for 'email'
 *      orgDisplayName: // value for 'orgDisplayName'
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const UpdateIdentitiesDocument = gql`
  mutation updateIdentities($input: BulkUpdatesIdentitiesInput!) {
    updateIdentities(input: $input) {
      _id
      email
      displayName
      owner {
        _id
        displayName
        email
      }
    }
  }
`;
export type UpdateIdentitiesMutationFn = Apollo.MutationFunction<
  UpdateIdentitiesMutation,
  UpdateIdentitiesMutationVariables
>;

/**
 * __useUpdateIdentitiesMutation__
 *
 * To run a mutation, you first call `useUpdateIdentitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdentitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdentitiesMutation, { data, loading, error }] = useUpdateIdentitiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIdentitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIdentitiesMutation,
    UpdateIdentitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIdentitiesMutation,
    UpdateIdentitiesMutationVariables
  >(UpdateIdentitiesDocument, options);
}
export type UpdateIdentitiesMutationHookResult = ReturnType<
  typeof useUpdateIdentitiesMutation
>;
export type UpdateIdentitiesMutationResult =
  Apollo.MutationResult<UpdateIdentitiesMutation>;
export type UpdateIdentitiesMutationOptions = Apollo.BaseMutationOptions<
  UpdateIdentitiesMutation,
  UpdateIdentitiesMutationVariables
>;
export const UpdateIdentityDocument = gql`
  mutation updateIdentity($input: IdentityUpdateBody!) {
    updateIdentity(input: $input) {
      _id
      email
      displayName
      org {
        _id
        displayName
        slug
      }
      owner {
        _id
        displayName
        email
        org {
          _id
          displayName
          slug
        }
      }
    }
  }
`;
export type UpdateIdentityMutationFn = Apollo.MutationFunction<
  UpdateIdentityMutation,
  UpdateIdentityMutationVariables
>;

/**
 * __useUpdateIdentityMutation__
 *
 * To run a mutation, you first call `useUpdateIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdentityMutation, { data, loading, error }] = useUpdateIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIdentityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIdentityMutation,
    UpdateIdentityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIdentityMutation,
    UpdateIdentityMutationVariables
  >(UpdateIdentityDocument, options);
}
export type UpdateIdentityMutationHookResult = ReturnType<
  typeof useUpdateIdentityMutation
>;
export type UpdateIdentityMutationResult =
  Apollo.MutationResult<UpdateIdentityMutation>;
export type UpdateIdentityMutationOptions = Apollo.BaseMutationOptions<
  UpdateIdentityMutation,
  UpdateIdentityMutationVariables
>;
export const UpdateOrgDocument = gql`
  mutation updateOrg($input: OrgUpdateBody!) {
    updateOrg(input: $input) {
      _id
      displayName
      status
      onboarding {
        connectedIDP
      }
    }
  }
`;
export type UpdateOrgMutationFn = Apollo.MutationFunction<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>;

/**
 * __useUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMutation, { data, loading, error }] = useUpdateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgMutation,
    UpdateOrgMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrgMutation, UpdateOrgMutationVariables>(
    UpdateOrgDocument,
    options
  );
}
export type UpdateOrgMutationHookResult = ReturnType<
  typeof useUpdateOrgMutation
>;
export type UpdateOrgMutationResult = Apollo.MutationResult<UpdateOrgMutation>;
export type UpdateOrgMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>;
export const GetActivitiesDocument = gql`
  query getActivities(
    $identityId: [ObjectID!]!
    $serviceId: [ObjectID!]!
    $offset: Int!
    $limit: Int!
    $sort: GetActivitiesSort
  ) {
    activities(
      identityId: $identityId
      serviceId: $serviceId
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      _id
      actorType
      actorName
      actionType
      org {
        _id
      }
      service {
        _id
      }
      identities {
        _id
      }
      timestamp
    }
    activitiesCount(identityId: $identityId, serviceId: $serviceId) {
      count
    }
  }
`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      identityId: // value for 'identityId'
 *      serviceId: // value for 'serviceId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  > &
    (
      | { variables: GetActivitiesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options
  );
}
export function useGetActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options
  );
}
export function useGetActivitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >(GetActivitiesDocument, options);
}
export type GetActivitiesQueryHookResult = ReturnType<
  typeof useGetActivitiesQuery
>;
export type GetActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesLazyQuery
>;
export type GetActivitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetActivitiesSuspenseQuery
>;
export type GetActivitiesQueryResult = Apollo.QueryResult<
  GetActivitiesQuery,
  GetActivitiesQueryVariables
>;
export const GetActivityDetailsDocument = gql`
  query getActivityDetails($_id: ObjectID!) {
    activityDetails(_id: $_id) {
      _id
      org {
        _id
      }
      detail {
        integrationId
        activityId
        ... on OktaActivityDetail {
          oktaUserId
          oktaUserDisplayName
          displayMessage
          eventType
          severity
        }
      }
    }
  }
`;

/**
 * __useGetActivityDetailsQuery__
 *
 * To run a query within a React component, call `useGetActivityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetActivityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  > &
    (
      | { variables: GetActivityDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >(GetActivityDetailsDocument, options);
}
export function useGetActivityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >(GetActivityDetailsDocument, options);
}
export function useGetActivityDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >(GetActivityDetailsDocument, options);
}
export type GetActivityDetailsQueryHookResult = ReturnType<
  typeof useGetActivityDetailsQuery
>;
export type GetActivityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetActivityDetailsLazyQuery
>;
export type GetActivityDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetActivityDetailsSuspenseQuery
>;
export type GetActivityDetailsQueryResult = Apollo.QueryResult<
  GetActivityDetailsQuery,
  GetActivityDetailsQueryVariables
>;
export const GetAuthResourceDetailsDocument = gql`
  query getAuthResourceDetails($_id: ObjectID!) {
    authResourceDetails(_id: $_id) {
      _id
      service {
        _id
        displayName
        integration {
          integrationId
        }
      }
      status
      externalId
      name
      lastActivity
      details {
        type
        ...OauthTokenDetailField
      }
      serviceIdentities {
        _id
        principal {
          principal
        }
      }
    }
  }
  ${OauthTokenDetailFieldFragmentDoc}
`;

/**
 * __useGetAuthResourceDetailsQuery__
 *
 * To run a query within a React component, call `useGetAuthResourceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthResourceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthResourceDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetAuthResourceDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  > &
    (
      | { variables: GetAuthResourceDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >(GetAuthResourceDetailsDocument, options);
}
export function useGetAuthResourceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >(GetAuthResourceDetailsDocument, options);
}
export function useGetAuthResourceDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >(GetAuthResourceDetailsDocument, options);
}
export type GetAuthResourceDetailsQueryHookResult = ReturnType<
  typeof useGetAuthResourceDetailsQuery
>;
export type GetAuthResourceDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAuthResourceDetailsLazyQuery
>;
export type GetAuthResourceDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetAuthResourceDetailsSuspenseQuery
>;
export type GetAuthResourceDetailsQueryResult = Apollo.QueryResult<
  GetAuthResourceDetailsQuery,
  GetAuthResourceDetailsQueryVariables
>;
export const GetAuthResourcesDocument = gql`
  query getAuthResources(
    $authResourceType: [AuthResourceType!]
    $serviceId: [ObjectID!]
    $status: [AuthResourceStatus!]
    $externalId: String
    $offset: Int!
    $limit: Int!
    $q: String
  ) {
    authResources(
      authResourceType: $authResourceType
      serviceId: $serviceId
      status: $status
      externalId: $externalId
      offset: $offset
      limit: $limit
      q: $q
    ) {
      _id
      service {
        _id
        integration {
          integrationId
        }
      }
      status
      externalId
      name
      details {
        type
      }
    }
  }
`;

/**
 * __useGetAuthResourcesQuery__
 *
 * To run a query within a React component, call `useGetAuthResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthResourcesQuery({
 *   variables: {
 *      authResourceType: // value for 'authResourceType'
 *      serviceId: // value for 'serviceId'
 *      status: // value for 'status'
 *      externalId: // value for 'externalId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGetAuthResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  > &
    (
      | { variables: GetAuthResourcesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthResourcesQuery, GetAuthResourcesQueryVariables>(
    GetAuthResourcesDocument,
    options
  );
}
export function useGetAuthResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  >(GetAuthResourcesDocument, options);
}
export function useGetAuthResourcesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  >(GetAuthResourcesDocument, options);
}
export type GetAuthResourcesQueryHookResult = ReturnType<
  typeof useGetAuthResourcesQuery
>;
export type GetAuthResourcesLazyQueryHookResult = ReturnType<
  typeof useGetAuthResourcesLazyQuery
>;
export type GetAuthResourcesSuspenseQueryHookResult = ReturnType<
  typeof useGetAuthResourcesSuspenseQuery
>;
export type GetAuthResourcesQueryResult = Apollo.QueryResult<
  GetAuthResourcesQuery,
  GetAuthResourcesQueryVariables
>;
export const GetClassificationRulesetDocument = gql`
  query getClassificationRuleset {
    classificationRuleset {
      _id
      rules {
        _id
        description
        type
        category
        property
        rule
        targetType
        scope
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetClassificationRulesetQuery__
 *
 * To run a query within a React component, call `useGetClassificationRulesetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationRulesetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationRulesetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassificationRulesetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >(GetClassificationRulesetDocument, options);
}
export function useGetClassificationRulesetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >(GetClassificationRulesetDocument, options);
}
export function useGetClassificationRulesetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >(GetClassificationRulesetDocument, options);
}
export type GetClassificationRulesetQueryHookResult = ReturnType<
  typeof useGetClassificationRulesetQuery
>;
export type GetClassificationRulesetLazyQueryHookResult = ReturnType<
  typeof useGetClassificationRulesetLazyQuery
>;
export type GetClassificationRulesetSuspenseQueryHookResult = ReturnType<
  typeof useGetClassificationRulesetSuspenseQuery
>;
export type GetClassificationRulesetQueryResult = Apollo.QueryResult<
  GetClassificationRulesetQuery,
  GetClassificationRulesetQueryVariables
>;
export const GetIdentitiesDocument = gql`
  query getIdentities(
    $serviceId: [ObjectID!]!
    $integrationId: [IntegrationId!]!
    $offset: Int!
    $limit: Int!
    $sort: GetIdentitiesSort!
    $q: String
  ) {
    identities(
      serviceId: $serviceId
      integrationId: $integrationId
      offset: $offset
      limit: $limit
      sort: $sort
      q: $q
    ) {
      _id
      displayName
      servicesCount
      status
      serviceIdentities {
        service {
          _id
          displayName
          integration {
            integrationId
            description
          }
        }
      }
    }
  }
`;

/**
 * __useGetIdentitiesQuery__
 *
 * To run a query within a React component, call `useGetIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentitiesQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      integrationId: // value for 'integrationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGetIdentitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIdentitiesQuery,
    GetIdentitiesQueryVariables
  > &
    (
      | { variables: GetIdentitiesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIdentitiesQuery, GetIdentitiesQueryVariables>(
    GetIdentitiesDocument,
    options
  );
}
export function useGetIdentitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIdentitiesQuery,
    GetIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIdentitiesQuery, GetIdentitiesQueryVariables>(
    GetIdentitiesDocument,
    options
  );
}
export function useGetIdentitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIdentitiesQuery,
    GetIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetIdentitiesQuery,
    GetIdentitiesQueryVariables
  >(GetIdentitiesDocument, options);
}
export type GetIdentitiesQueryHookResult = ReturnType<
  typeof useGetIdentitiesQuery
>;
export type GetIdentitiesLazyQueryHookResult = ReturnType<
  typeof useGetIdentitiesLazyQuery
>;
export type GetIdentitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetIdentitiesSuspenseQuery
>;
export type GetIdentitiesQueryResult = Apollo.QueryResult<
  GetIdentitiesQuery,
  GetIdentitiesQueryVariables
>;
export const GetAllIdentitiesDocument = gql`
  query getAllIdentities(
    $serviceId: [ObjectID!]
    $integrationId: [IntegrationId!]
    $offset: Int
    $limit: Int
    $sort: GetIdentitiesSort
    $q: String
    $accountType: [IdentityAccountType]
    $owners: [ObjectID]
    $scope: IdentityScope
  ) {
    identities(
      serviceId: $serviceId
      integrationId: $integrationId
      offset: $offset
      limit: $limit
      sort: $sort
      q: $q
      accountType: $accountType
      owners: $owners
      scope: $scope
    ) {
      _id
      displayName
      classification {
        accountType
        decidedAt
        scope
      }
      email
      status
      servicesCount
      owner {
        _id
        displayName
        email
        status
      }
      serviceIdentities {
        _id
        externalId
        service {
          _id
          displayName
          integration {
            integrationId
            description
          }
        }
        authResources {
          _id
          name
          externalId
          details {
            type
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllIdentitiesQuery__
 *
 * To run a query within a React component, call `useGetAllIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIdentitiesQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      integrationId: // value for 'integrationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      q: // value for 'q'
 *      accountType: // value for 'accountType'
 *      owners: // value for 'owners'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetAllIdentitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllIdentitiesQuery, GetAllIdentitiesQueryVariables>(
    GetAllIdentitiesDocument,
    options
  );
}
export function useGetAllIdentitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >(GetAllIdentitiesDocument, options);
}
export function useGetAllIdentitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >(GetAllIdentitiesDocument, options);
}
export type GetAllIdentitiesQueryHookResult = ReturnType<
  typeof useGetAllIdentitiesQuery
>;
export type GetAllIdentitiesLazyQueryHookResult = ReturnType<
  typeof useGetAllIdentitiesLazyQuery
>;
export type GetAllIdentitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllIdentitiesSuspenseQuery
>;
export type GetAllIdentitiesQueryResult = Apollo.QueryResult<
  GetAllIdentitiesQuery,
  GetAllIdentitiesQueryVariables
>;
export const GetIdentityDetailsDocument = gql`
  query getIdentityDetails($_id: ObjectID!, $offset: Int, $limit: Int) {
    identityDetails(_id: $_id) {
      _id
      displayName
      owner {
        _id
        displayName
        email
        status
      }
      servicesCount
      status
      serviceIdentities(offset: $offset, limit: $limit) {
        _id
        status
        service {
          _id
          displayName
          integration {
            integrationId
            description
            authorizedScopes
            activeAuthType
            status
          }
          status
          lastActivityIngest
        }
      }
      classification {
        decidedAt
        accountType
      }
    }
  }
`;

/**
 * __useGetIdentityDetailsQuery__
 *
 * To run a query within a React component, call `useGetIdentityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetIdentityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  > &
    (
      | { variables: GetIdentityDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >(GetIdentityDetailsDocument, options);
}
export function useGetIdentityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >(GetIdentityDetailsDocument, options);
}
export function useGetIdentityDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >(GetIdentityDetailsDocument, options);
}
export type GetIdentityDetailsQueryHookResult = ReturnType<
  typeof useGetIdentityDetailsQuery
>;
export type GetIdentityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetIdentityDetailsLazyQuery
>;
export type GetIdentityDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetIdentityDetailsSuspenseQuery
>;
export type GetIdentityDetailsQueryResult = Apollo.QueryResult<
  GetIdentityDetailsQuery,
  GetIdentityDetailsQueryVariables
>;
export const IngestjobsDocument = gql`
  query Ingestjobs(
    $serviceId: [ObjectID!]
    $type: JobType
    $offset: Int!
    $limit: Int!
  ) {
    ingestjobs(
      serviceId: $serviceId
      type: $type
      offset: $offset
      limit: $limit
    ) {
      ...IngestJobFields
    }
  }
  ${IngestJobFieldsFragmentDoc}
`;

/**
 * __useIngestjobsQuery__
 *
 * To run a query within a React component, call `useIngestjobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngestjobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngestjobsQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      type: // value for 'type'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useIngestjobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IngestjobsQuery,
    IngestjobsQueryVariables
  > &
    (
      | { variables: IngestjobsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IngestjobsQuery, IngestjobsQueryVariables>(
    IngestjobsDocument,
    options
  );
}
export function useIngestjobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IngestjobsQuery,
    IngestjobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IngestjobsQuery, IngestjobsQueryVariables>(
    IngestjobsDocument,
    options
  );
}
export function useIngestjobsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IngestjobsQuery,
    IngestjobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IngestjobsQuery, IngestjobsQueryVariables>(
    IngestjobsDocument,
    options
  );
}
export type IngestjobsQueryHookResult = ReturnType<typeof useIngestjobsQuery>;
export type IngestjobsLazyQueryHookResult = ReturnType<
  typeof useIngestjobsLazyQuery
>;
export type IngestjobsSuspenseQueryHookResult = ReturnType<
  typeof useIngestjobsSuspenseQuery
>;
export type IngestjobsQueryResult = Apollo.QueryResult<
  IngestjobsQuery,
  IngestjobsQueryVariables
>;
export const IngestjobDocument = gql`
  query Ingestjob($_id: ObjectID!) {
    getIngestJob(_id: $_id) {
      ...IngestJobFields
    }
  }
  ${IngestJobFieldsFragmentDoc}
`;

/**
 * __useIngestjobQuery__
 *
 * To run a query within a React component, call `useIngestjobQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngestjobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngestjobQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useIngestjobQuery(
  baseOptions: Apollo.QueryHookOptions<
    IngestjobQuery,
    IngestjobQueryVariables
  > &
    ({ variables: IngestjobQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IngestjobQuery, IngestjobQueryVariables>(
    IngestjobDocument,
    options
  );
}
export function useIngestjobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IngestjobQuery,
    IngestjobQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IngestjobQuery, IngestjobQueryVariables>(
    IngestjobDocument,
    options
  );
}
export function useIngestjobSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IngestjobQuery,
    IngestjobQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IngestjobQuery, IngestjobQueryVariables>(
    IngestjobDocument,
    options
  );
}
export type IngestjobQueryHookResult = ReturnType<typeof useIngestjobQuery>;
export type IngestjobLazyQueryHookResult = ReturnType<
  typeof useIngestjobLazyQuery
>;
export type IngestjobSuspenseQueryHookResult = ReturnType<
  typeof useIngestjobSuspenseQuery
>;
export type IngestjobQueryResult = Apollo.QueryResult<
  IngestjobQuery,
  IngestjobQueryVariables
>;
export const GetIntegrationCountsDocument = gql`
  query getIntegrationCounts($integrationId: IntegrationId) {
    getIntegrationCounts(integrationId: $integrationId) {
      integrationId
      count
    }
  }
`;

/**
 * __useGetIntegrationCountsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationCountsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetIntegrationCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >(GetIntegrationCountsDocument, options);
}
export function useGetIntegrationCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >(GetIntegrationCountsDocument, options);
}
export function useGetIntegrationCountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >(GetIntegrationCountsDocument, options);
}
export type GetIntegrationCountsQueryHookResult = ReturnType<
  typeof useGetIntegrationCountsQuery
>;
export type GetIntegrationCountsLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationCountsLazyQuery
>;
export type GetIntegrationCountsSuspenseQueryHookResult = ReturnType<
  typeof useGetIntegrationCountsSuspenseQuery
>;
export type GetIntegrationCountsQueryResult = Apollo.QueryResult<
  GetIntegrationCountsQuery,
  GetIntegrationCountsQueryVariables
>;
export const LatestJobChainDocument = gql`
  query LatestJobChain($serviceId: ObjectID!, $type: JobChainType!) {
    latestJobChain(serviceId: $serviceId, type: $type) {
      _id
      startedAt
      completedAt
      status
      error
      missingScopes
    }
  }
`;

/**
 * __useLatestJobChainQuery__
 *
 * To run a query within a React component, call `useLatestJobChainQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestJobChainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestJobChainQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLatestJobChainQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestJobChainQuery,
    LatestJobChainQueryVariables
  > &
    (
      | { variables: LatestJobChainQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestJobChainQuery, LatestJobChainQueryVariables>(
    LatestJobChainDocument,
    options
  );
}
export function useLatestJobChainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestJobChainQuery,
    LatestJobChainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestJobChainQuery, LatestJobChainQueryVariables>(
    LatestJobChainDocument,
    options
  );
}
export function useLatestJobChainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LatestJobChainQuery,
    LatestJobChainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LatestJobChainQuery,
    LatestJobChainQueryVariables
  >(LatestJobChainDocument, options);
}
export type LatestJobChainQueryHookResult = ReturnType<
  typeof useLatestJobChainQuery
>;
export type LatestJobChainLazyQueryHookResult = ReturnType<
  typeof useLatestJobChainLazyQuery
>;
export type LatestJobChainSuspenseQueryHookResult = ReturnType<
  typeof useLatestJobChainSuspenseQuery
>;
export type LatestJobChainQueryResult = Apollo.QueryResult<
  LatestJobChainQuery,
  LatestJobChainQueryVariables
>;
export const GetMeDocument = gql`
  query getMe {
    me {
      currentOrg {
        ...CurrentOrgFields
      }
      currentUser {
        ...CurrentUserFields
      }
    }
  }
  ${CurrentOrgFieldsFragmentDoc}
  ${CurrentUserFieldsFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export function useGetMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<
  typeof useGetMeSuspenseQuery
>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetMyOrgsDocument = gql`
  query getMyOrgs {
    myOrgs {
      ...CurrentOrgFields
    }
  }
  ${CurrentOrgFieldsFragmentDoc}
`;

/**
 * __useGetMyOrgsQuery__
 *
 * To run a query within a React component, call `useGetMyOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrgsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyOrgsQuery, GetMyOrgsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOrgsQuery, GetMyOrgsQueryVariables>(
    GetMyOrgsDocument,
    options
  );
}
export function useGetMyOrgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyOrgsQuery,
    GetMyOrgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOrgsQuery, GetMyOrgsQueryVariables>(
    GetMyOrgsDocument,
    options
  );
}
export function useGetMyOrgsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyOrgsQuery,
    GetMyOrgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMyOrgsQuery, GetMyOrgsQueryVariables>(
    GetMyOrgsDocument,
    options
  );
}
export type GetMyOrgsQueryHookResult = ReturnType<typeof useGetMyOrgsQuery>;
export type GetMyOrgsLazyQueryHookResult = ReturnType<
  typeof useGetMyOrgsLazyQuery
>;
export type GetMyOrgsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyOrgsSuspenseQuery
>;
export type GetMyOrgsQueryResult = Apollo.QueryResult<
  GetMyOrgsQuery,
  GetMyOrgsQueryVariables
>;
export const GetPermissionsDocument = gql`
  query getPermissions(
    $serviceIdentityId: ObjectID!
    $authenticatorId: ObjectID!
    $limit: Int
    $offset: Int
  ) {
    permissions(
      serviceIdentityId: $serviceIdentityId
      authenticatorId: $authenticatorId
      limit: $limit
      offset: $offset
    ) {
      _id
      externalId
      name
      createdOn
      lastActivity
      details {
        type
        ...PermissionSetDetailField
      }
    }
  }
  ${PermissionSetDetailFieldFragmentDoc}
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      serviceIdentityId: // value for 'serviceIdentityId'
 *      authenticatorId: // value for 'authenticatorId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  > &
    (
      | { variables: GetPermissionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >(GetPermissionsDocument, options);
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetPermissionsSuspenseQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const GetServiceDetailsDocument = gql`
  query getServiceDetails($_id: ObjectID!, $offset: Int, $limit: Int) {
    serviceDetails(_id: $_id) {
      _id
      displayName
      status
      integration {
        integrationId
      }
      serviceIdentities(offset: $offset, limit: $limit) {
        _id
        identity {
          ... on Identity {
            _id
            displayName
            classification {
              decidedAt
              accountType
            }
            email
            owner {
              _id
              displayName
              email
              status
            }
          }
          ... on Error {
            message
          }
        }
        status
      }
    }
  }
`;

/**
 * __useGetServiceDetailsQuery__
 *
 * To run a query within a React component, call `useGetServiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetServiceDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  > &
    (
      | { variables: GetServiceDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >(GetServiceDetailsDocument, options);
}
export function useGetServiceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >(GetServiceDetailsDocument, options);
}
export function useGetServiceDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >(GetServiceDetailsDocument, options);
}
export type GetServiceDetailsQueryHookResult = ReturnType<
  typeof useGetServiceDetailsQuery
>;
export type GetServiceDetailsLazyQueryHookResult = ReturnType<
  typeof useGetServiceDetailsLazyQuery
>;
export type GetServiceDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceDetailsSuspenseQuery
>;
export type GetServiceDetailsQueryResult = Apollo.QueryResult<
  GetServiceDetailsQuery,
  GetServiceDetailsQueryVariables
>;
export const GetServiceIdentityCountsDocument = gql`
  query getServiceIdentityCounts($_id: ObjectID!) {
    serviceDetails(_id: $_id) {
      serviceIdentityCount
    }
  }
`;

/**
 * __useGetServiceIdentityCountsQuery__
 *
 * To run a query within a React component, call `useGetServiceIdentityCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceIdentityCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceIdentityCountsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetServiceIdentityCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  > &
    (
      | { variables: GetServiceIdentityCountsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >(GetServiceIdentityCountsDocument, options);
}
export function useGetServiceIdentityCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >(GetServiceIdentityCountsDocument, options);
}
export function useGetServiceIdentityCountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >(GetServiceIdentityCountsDocument, options);
}
export type GetServiceIdentityCountsQueryHookResult = ReturnType<
  typeof useGetServiceIdentityCountsQuery
>;
export type GetServiceIdentityCountsLazyQueryHookResult = ReturnType<
  typeof useGetServiceIdentityCountsLazyQuery
>;
export type GetServiceIdentityCountsSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceIdentityCountsSuspenseQuery
>;
export type GetServiceIdentityCountsQueryResult = Apollo.QueryResult<
  GetServiceIdentityCountsQuery,
  GetServiceIdentityCountsQueryVariables
>;
export const GetAllServiceIdentitiesDocument = gql`
  query getAllServiceIdentities(
    $integrationId: [IntegrationId!]
    $authResourceTypes: [AuthResourceType!]
    $owners: [ObjectID!]
    $nonHumanFlag: Boolean
    $offset: Int
    $limit: Int
    $sort: GetServiceIdentitiesSort
    $q: String
  ) {
    serviceIdentities(
      integrationId: $integrationId
      authResourceTypes: $authResourceTypes
      owners: $owners
      nonHumanFlag: $nonHumanFlag
      offset: $offset
      limit: $limit
      sort: $sort
      q: $q
    ) {
      _id
      externalId
      service {
        _id
        displayName
        integration {
          integrationId
          description
        }
      }
      identity {
        ...IdentityFieldForList
      }
      authResources {
        _id
        name
        externalId
        details {
          type
        }
      }
    }
  }
  ${IdentityFieldForListFragmentDoc}
`;

/**
 * __useGetAllServiceIdentitiesQuery__
 *
 * To run a query within a React component, call `useGetAllServiceIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServiceIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServiceIdentitiesQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      authResourceTypes: // value for 'authResourceTypes'
 *      owners: // value for 'owners'
 *      nonHumanFlag: // value for 'nonHumanFlag'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGetAllServiceIdentitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >(GetAllServiceIdentitiesDocument, options);
}
export function useGetAllServiceIdentitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >(GetAllServiceIdentitiesDocument, options);
}
export function useGetAllServiceIdentitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >(GetAllServiceIdentitiesDocument, options);
}
export type GetAllServiceIdentitiesQueryHookResult = ReturnType<
  typeof useGetAllServiceIdentitiesQuery
>;
export type GetAllServiceIdentitiesLazyQueryHookResult = ReturnType<
  typeof useGetAllServiceIdentitiesLazyQuery
>;
export type GetAllServiceIdentitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllServiceIdentitiesSuspenseQuery
>;
export type GetAllServiceIdentitiesQueryResult = Apollo.QueryResult<
  GetAllServiceIdentitiesQuery,
  GetAllServiceIdentitiesQueryVariables
>;
export const GetServiceIdentityAuthResourcesDocument = gql`
  query getServiceIdentityAuthResources(
    $id: ObjectID!
    $start: Int
    $limit: Int
    $types: [AuthResourceType!]
    $sort: GetAuthResourcesSort
  ) {
    serviceIdentity(id: $id) {
      _id
      authResources(start: $start, limit: $limit, types: $types, sort: $sort) {
        _id
        externalId
        name
        createdOn
        lastActivity
        details {
          type
          ...PermissionSetDetailField
        }
      }
    }
  }
  ${PermissionSetDetailFieldFragmentDoc}
`;

/**
 * __useGetServiceIdentityAuthResourcesQuery__
 *
 * To run a query within a React component, call `useGetServiceIdentityAuthResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceIdentityAuthResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceIdentityAuthResourcesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *      types: // value for 'types'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetServiceIdentityAuthResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  > &
    (
      | {
          variables: GetServiceIdentityAuthResourcesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >(GetServiceIdentityAuthResourcesDocument, options);
}
export function useGetServiceIdentityAuthResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >(GetServiceIdentityAuthResourcesDocument, options);
}
export function useGetServiceIdentityAuthResourcesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >(GetServiceIdentityAuthResourcesDocument, options);
}
export type GetServiceIdentityAuthResourcesQueryHookResult = ReturnType<
  typeof useGetServiceIdentityAuthResourcesQuery
>;
export type GetServiceIdentityAuthResourcesLazyQueryHookResult = ReturnType<
  typeof useGetServiceIdentityAuthResourcesLazyQuery
>;
export type GetServiceIdentityAuthResourcesSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceIdentityAuthResourcesSuspenseQuery
>;
export type GetServiceIdentityAuthResourcesQueryResult = Apollo.QueryResult<
  GetServiceIdentityAuthResourcesQuery,
  GetServiceIdentityAuthResourcesQueryVariables
>;
export const GetServiceIdentityDetailsDocument = gql`
  query getServiceIdentityDetails($id: ObjectID!) {
    serviceIdentity(id: $id) {
      _id
      externalId
      lastActivity
      service {
        _id
        displayName
        integration {
          integrationId
          description
        }
      }
      identity {
        ...IdentityField
      }
    }
  }
  ${IdentityFieldFragmentDoc}
`;

/**
 * __useGetServiceIdentityDetailsQuery__
 *
 * To run a query within a React component, call `useGetServiceIdentityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceIdentityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceIdentityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceIdentityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  > &
    (
      | { variables: GetServiceIdentityDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >(GetServiceIdentityDetailsDocument, options);
}
export function useGetServiceIdentityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >(GetServiceIdentityDetailsDocument, options);
}
export function useGetServiceIdentityDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >(GetServiceIdentityDetailsDocument, options);
}
export type GetServiceIdentityDetailsQueryHookResult = ReturnType<
  typeof useGetServiceIdentityDetailsQuery
>;
export type GetServiceIdentityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetServiceIdentityDetailsLazyQuery
>;
export type GetServiceIdentityDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceIdentityDetailsSuspenseQuery
>;
export type GetServiceIdentityDetailsQueryResult = Apollo.QueryResult<
  GetServiceIdentityDetailsQuery,
  GetServiceIdentityDetailsQueryVariables
>;
export const GetServicesDocument = gql`
  query getServices(
    $identityId: [ObjectID!]
    $integrationId: [IntegrationId!]
    $offset: Int
    $limit: Int
    $q: String
    $isIntegration: Boolean
  ) {
    services(
      identityId: $identityId
      integrationId: $integrationId
      offset: $offset
      limit: $limit
      q: $q
      isIntegration: $isIntegration
    ) {
      _id
      displayName
      status
      integration {
        integrationId
        description
        activeAuthType
        authFields
      }
      serviceIdentityCount
    }
  }
`;

/**
 * __useGetServicesQuery__
 *
 * To run a query within a React component, call `useGetServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesQuery({
 *   variables: {
 *      identityId: // value for 'identityId'
 *      integrationId: // value for 'integrationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      q: // value for 'q'
 *      isIntegration: // value for 'isIntegration'
 *   },
 * });
 */
export function useGetServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServicesQuery,
    GetServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServicesQuery, GetServicesQueryVariables>(
    GetServicesDocument,
    options
  );
}
export function useGetServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServicesQuery,
    GetServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServicesQuery, GetServicesQueryVariables>(
    GetServicesDocument,
    options
  );
}
export function useGetServicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServicesQuery,
    GetServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetServicesQuery, GetServicesQueryVariables>(
    GetServicesDocument,
    options
  );
}
export type GetServicesQueryHookResult = ReturnType<typeof useGetServicesQuery>;
export type GetServicesLazyQueryHookResult = ReturnType<
  typeof useGetServicesLazyQuery
>;
export type GetServicesSuspenseQueryHookResult = ReturnType<
  typeof useGetServicesSuspenseQuery
>;
export type GetServicesQueryResult = Apollo.QueryResult<
  GetServicesQuery,
  GetServicesQueryVariables
>;
export const GetUsersDocument = gql`
  query getUsers(
    $offset: Int
    $limit: Int
    $q: String
    $sort: GetUsersSort
    $status: [UserStatus]
  ) {
    users(offset: $offset, limit: $limit, q: $q, sort: $sort, status: $status) {
      _id
      org {
        _id
      }
      displayName
      email
      status
      role
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      q: // value for 'q'
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersSuspenseQueryHookResult = ReturnType<
  typeof useGetUsersSuspenseQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
